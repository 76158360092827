import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogProps,
  IconButton,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

type StyledDialogProps = PropsWithChildren<
  DialogProps & {
    title: string | JSX.Element;
    onClose: () => void;
  }
>;

export const StyledDialog: FunctionComponent<StyledDialogProps> = (props) => {
  const { title, children, onClose } = props;
  const dialogProps = {
    ...props,
    title: undefined,
    children: undefined,
  };

  return (
    <Dialog fullWidth={true} maxWidth="lg" {...dialogProps}>
      <Container>
        <DialogHeader title={title} />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>{children}</DialogContent>
      </Container>
    </Dialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px !important;
  margin-bottom: 0 !important;
`;

const DialogHeader: FunctionComponent<{ title?: string }> = ({ title }) => (
  <StyledDialogTitle variant="h2">{title}</StyledDialogTitle>
);
