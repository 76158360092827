import React from "react";
import styled from "styled-components";
import { DataGrid as MuiDataGrid, DataGridProps } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { colors } from "../themes/colors";

export const StyledGridOverlay = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const NoRowsOverlay = (): JSX.Element => (
  <StyledGridOverlay>
    <Box style={{ width: "100%", textAlign: "center" }}>
      <Typography variant="h6" align="center" sx={{ fontSize: "0.9rem" }}>
        No data to show
      </Typography>
    </Box>
  </StyledGridOverlay>
);

// Setting overridable defaults for MuiDataGrid component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BaseDataGrid = styled((props: any) => {
  return (
    <MuiDataGrid
      density="compact"
      autoHeight={true}
      slots={{
        noRowsOverlay: NoRowsOverlay,
        ...props.slots,
      }}
      getRowId={(row) => JSON.stringify(row)}
      disableRowSelectionOnClick
      {...props}
    />
  );
})`
  & .MuiDataGrid-cell {
    font-size: 12px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-size: 12px;
    font-weight: bold;
  }

  & .MuiToolbar-root,
  & .MuiTablePagination-selectLabel,
  & .MuiTablePagination-displayedRows {
    font-size: 12px;
  }
`;

export const StyledDataGrid = styled(BaseDataGrid)<DataGridProps>`
  .MuiDataGrid-columnHeader {
    background-color: ${colors.PURPLE_LIGHTEST};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PrintLookStyledDataGrid = styled((props: any) => (
  <BaseDataGrid
    disableColumnMenu={true}
    disableRowSelectionOnClick={true}
    disableColumnSelector={true}
    disableColumnFilter={true}
    hideFooter={true}
    {...props}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns={props.columns.map((c: any) => {
      c.sortable = false;
      return c;
    })}
  />
))({
  margin: 12,
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 700,
  },
});
