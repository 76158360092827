import React, { FunctionComponent } from "react";
import { Address } from "../models/address";
import { Box, Typography } from "@mui/material";
import { colors } from "../themes/colors";

export const AddressView: FunctionComponent<Address> = ({
  line1,
  line2,
  city,
  state,
  country,
  postCode,
}) => {
  return (
    <Box>
      <Typography>{line1}</Typography>
      {!!line2 && <Typography>{line2}</Typography>}
      <Typography>
        {city}, {state}
      </Typography>
      {!!country && country != "US" && <Typography>{country}</Typography>}
      <Typography>{postCode}</Typography>
    </Box>
  );
};

export const NullableAddressView: FunctionComponent<Address | undefined> = (
  address,
) => {
  if (address?.line1 === undefined || address.line1.length === 0) {
    return (
      <Typography sx={{ color: colors.GREY }}>No address on file</Typography>
    );
  }

  return <AddressView {...address} />;
};
