import { createContext } from "react";
import { IdToken, User } from "@auth0/auth0-react";

export type UserContextProps = {
  user?: User;
  accessToken?: string;
  idToken?: IdToken;
  refreshAccessToken: () => void;
};

export const UserContext = createContext<UserContextProps>(
  {} as UserContextProps,
);
